<template>
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <div slot="header" class="header">
      <div class="dict-title">
        字典名称：
        <el-tag type="success">{{ $route.query.dictName }}</el-tag>
      </div>
      <div>
        <el-button v-checkbtn="isShowBtn(AUTH_BTN.dict_value_list_add)" type="primary" size="small" icon="el-icon-plus" @click="goAdd"
          >添加</el-button
        >
        <el-button type="info" size="small" icon="el-icon-back" @click="$router.back()">返回</el-button>
      </div>
    </div>
    <tp-table :tableData="dataItems" :columns="columns" />
    <!-- 详情 -->
    <el-dialog title="字典值" :visible.sync="dialogVisible" width="width">
      <el-form ref="form" :model="formData" label-width="80px">
        <el-form-item label="名称" prop="name" :rules="rules">
          <el-input style="width: 50%" v-model="formData.name" size="small" placeholder="字典值名称"></el-input>
        </el-form-item>
        <el-form-item label="key" prop="value" :rules="rules">
          <el-input style="width: 50%" v-model="formData.value" size="small" placeholder="字典值key"></el-input>
        </el-form-item>
        <el-form-item label="排序">
          <el-input style="width: 50%" v-model="formData.sort" size="small" placeholder="排序"></el-input>
        </el-form-item>
        <el-form-item label="备注">
          <el-input style="width: 50%" v-model="formData.remark" type="textarea" size="small" placeholder="备注"></el-input>
        </el-form-item>
        <el-form-item label="">
          <el-button @click="dialogVisible = false" size="small">取消</el-button>
          <el-button type="primary" @click="save" size="small">保存</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { getDictValues, delDictValue, addDictValue, editDictValue, getDictValue } from './api'
import { authBtnMixin } from '@/mixins/authBtnMixin'
const columns = [
  {
    label: '字典值名称',
    minWidth: '200',
    prop: 'name',
    customRender(h, row) {
      return (
        <p class="one-line" title={row['name']}>
          {row['name']}
        </p>
      )
    }
  },
  {
    label: '字典值',
    minWidth: '300',
    prop: 'value',
    customRender(h, row) {
      return <p>{row['value']}</p>
    }
  },
  {
    label: '备注',
    minWidth: '250',
    prop: 'remark',
    customRender(h, row) {
      return <p>{row['remark']}</p>
    }
  },
  {
    label: '操作',
    fixed: 'right',
    width: '300',
    customRender(h, row) {
      return (
        <div>
          <el-button
            disabled={!this.isShowBtn(this.AUTH_BTN.dict_value_list_edit)}
            type="primary"
            size="mini"
            icon="el-icon-edit"
            onClick={() => this.goEdit(row['id'])}
          >
            编辑
          </el-button>
          <el-button
            disabled={!this.isShowBtn(this.AUTH_BTN.dict_value_list_del)}
            onClick={() => this.goRemove(row['id'])}
            style="margin-left:10px"
            type="danger"
            size="mini"
            icon="el-icon-delete"
          >
            删除
          </el-button>
        </div>
      )
    }
  }
]
export default {
  name: 'DictValueList',
  mixins: [authBtnMixin],
  data() {
    return {
      rules: [{ required: true, message: '该项不能为空', trigger: 'blur' }],
      dialogVisible: false,
      columns,
      dataItems: [],
      formData: {
        dict_key: '',
        name: '',
        value: '',
        remark: '',
        sort: '1'
      }
    }
  },
  provide() {
    return {
      context: this
    }
  },
  mounted() {
    this.queryDictValues()
  },
  methods: {
    // 添加字典值
    goAdd() {
      this.formData = { dict_key: '', name: '', key: '', remark: '', sort: '1' }
      this.dialogVisible = true
    },
    // 值详情
    async getDictValue(id) {
      this.formData = await getDictValue(id)
    },
    // 编辑值
    goEdit(id) {
      this.getDictValue(id)
      this.dialogVisible = true
    },
    // 保存值
    save() {
      this.formData.dict_key = this.$route.query.dict_key
      this.$refs.form.validate(valid => {
        if (valid) {
          if (this.formData.id) {
            editDictValue(this.formData).then(() => {
              this.$message.success('编辑成功')
              this.dialogVisible = false
              this.queryDictValues()
            })
          } else {
            addDictValue(this.formData).then(() => {
              this.$message.success('添加成功')
              this.dialogVisible = false
              this.queryDictValues()
            })
          }
        }
      })
    },
    // 删除
    async goRemove(id) {
      this.$confirm('此操作将永久删除该字典值, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          delDictValue(id).then(() => {
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
            this.queryDictValues()
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },

    // 值列表
    async queryDictValues() {
      let res = await getDictValues(this.$route.query.dict_key)
      this.dataItems = res.data || []
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;

  .dict-title {
    font-weight: bold;
  }

  .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
}
</style>
